import axios from 'axios';
import authHeader from './auth-header';

import constants from '../constants';
import { parseError } from '@/utils/utils'
var API_URL = constants.API_URL + 'documentRequest/';

class DocumentRequestService {
  async getDetails(documentRequestId) {
    try {
      let response = await axios.get(API_URL + documentRequestId, { headers: authHeader() });
      return { success: true, data: response.data } ;
    }
    catch (err) {
      return { success: false, data: parseError(err) } ;
    }
  }

  async sendMail(documentRequestId, email) {
    try {
      let response = await axios.post(API_URL + 'sendMail', { documentRequestId: documentRequestId, email: email }, { headers: authHeader() });
      return response;
    }
    catch (error){
      return parseError(error);
    }
  }

  async updateStatus(documentRequestId, statusEnum) {
    try {
      let response = await axios.post(API_URL + 'updateStatus', { documentRequestId: documentRequestId, statusEnum: statusEnum }, { headers: authHeader() });
      return response;
    }
    catch (error){
      return parseError(error);
    }
  }
}

export default new DocumentRequestService();
