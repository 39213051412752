<template>  
  <div :class="{ 'shrinkreport-content': drawer && !isMobile }" style="margin-top:75px;" v-if="!loading && data && data.request">
    <v-row style="margin:0px;">
      <Breadcrumbs style="display:inline-block;" />
    </v-row>

    <v-row class="mr-1">
      <v-col cols="12" md="5">
        <v-card class="pt-4 pl-4 pr-4 pb-4 ml-3">
          <v-btn
              v-if="data.request.clickUpTaskId"
              fab
              color="gray"
              style="float:right"
              title="Click Up"
              :href="'https://app.clickup.com/t/' + data.request.clickUpTaskId" target="_blank">
              <img src="/assets/icon/clickup-symbol_color.svg" height="35" />
          </v-btn>
          <h3>{{$t(DOCUMENT_REQUEST_TYPES_TEXT[data.request.documentTypeEnum])}}</h3>
          <h5>{{ $t('reportInfo.dealername') }}</h5>
          <p>{{ data.request.site_report.project.dealer.name }}</p>
          
          <h5>{{ $t('reportInfo.site_address') }}</h5>
          <p>{{ getLongFormattedAddress(countries, data.request.site_report.project.isCustomerAddress ? data.request.site_report.project.customerAddress : data.request.site_report.project.siteAddress) }}</p>

          <h5>{{ $t('reportInfo.projectinfo') }}</h5>
          <v-text-field :label="$t('reportInfo.referenceNumber')" :value="data.request.site_report.project.referenceNumber ?? ''"
                  @change="v => valueChanged('referenceNumber', v)" maxlength="45" hide-details >
                </v-text-field>
        </v-card>
      </v-col>
      <v-col cols="12" lg="4" md="7">
        <v-card class="pt-4 pl-4 pr-4 pb-4 ml-3">

          <div style="position:relative;width:100%;">
            <h3 class="required">{{ $t("reportInfo.file") }}</h3>
            <div class="upload-btn-wrapper">
              <v-btn fab color="#009f4d" dark v-on:click="$refs.docUpload.click();">
                <v-progress-circular v-if="uploading" color="white" indeterminate></v-progress-circular>
                <v-icon v-else>mdi-cloud-upload</v-icon>
              </v-btn>
              <input style="display:none;" type="file" name="file" multiple ref="docUpload" v-on:change="handleFileUpload" accept="image/png, image/jpeg, image/heic, image/webp, image/gif, application/pdf"/>
            </div>
          </div>

          <div
            @dragover.prevent="dragOver"
            @dragleave.prevent="dragLeave"
            @drop.prevent="dropFile($event)"
            class="mt-3"
          >
            <div v-if="data.files.length < 1" class="request-container">
              <div class="requests-anchor">
                  <img class="required-img">
                  <span class="wrappedfile">{{ $t('reportInfo.required') }}</span>
              </div>
            </div>
            <div class="request-container"
              v-for="(doc, index) in data.files"
              v-bind:key="'D'+ index">
                  <div target="_blank" class="requests-anchor" @click="doc.isImage ? thumbnailClicked(doc, index) : downloadFile(doc, true)" style="cursor:pointer;">
                    <img :src="doc.url.thumbnail" v-if="doc.url.thumbnail != null">
                    <img v-else src="/assets/icon/doc-file.svg">
                    <span v-if="doc.renaming" class="editingFile">
                      <v-text-field :id="doc.inputId" v-model="doc.filename" @blur="renameFile(doc, 'disable')" @keyup.native.enter="renameFile(doc, 'disable')" maxlength="200"></v-text-field>
                    </span>
                    <span v-else-if="doc.isImage" class="wrappedfile">{{ doc.filename }}<br/>{{ doc.createdAt }}</span>
                    <span v-else class="wrappedfile">{{ doc.filename }}.{{  doc.extension }}<br/>{{ doc.createdAt }}</span>
                  </div>
                  <span v-if="!doc.renaming">
                    <v-icon v-on:click="confirmDelete(doc)" v-if="true" class="delete-photo-icon" :title="$t('reportInfo.delete_file')">mdi-close</v-icon>
                    <v-icon v-on:click="renameFile(doc, 'enable')" class="rename-photo-icon" :title="$t('reportInfo.rename_file')">mdi-form-textbox</v-icon>
                    <v-icon v-on:click="downloadFile(doc)" class="download-photo-icon" :title="$t('reportInfo.download_file')">mdi-download</v-icon>
                  </span>
            </div>
          </div>
          <br/>
          <v-text-field 
            dense
            :label="$t('users.email')"
            :rules="emailsRules"
            maxlength="245"
            v-model="email" 
            hide-details>
          </v-text-field>

          <br/>
          <v-btn @click="sendMail" rounded color="primary" v-bind:disabled="disableBtn">
              <v-icon>mdi-email</v-icon> 
              <div style="text-transform:none;font-size:11px;" class="ml-5">{{ $t('general.send')}}</div>
          </v-btn>
          <br/><br/>
          <p>{{ $t('documentRequests.sendNotes') }}</p>
          <h3>{{ $t("reportInfo.history") }}</h3>
          <div 
              v-for="(log, index) in data.logs"
              v-bind:key="'D'+ index">
              {{ $t('documentRequests.history', {firstname: log.user.firstname, lastname: log.user.lastname, createdAt:log.createdAt }) }}
          </div>
          <div v-if="data.logs.length == 0">
            {{ $t("documentRequests.historyNone") }}
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="confirmDialog" max-width="500">
      <v-card>
        <v-card-title>
          {{ confirmDialogTitle }}
        </v-card-title>
        <v-card-text>{{ confirmDialogBody }}</v-card-text>
        <v-card-actions>
          <v-btn color="green darken-1" text @click="confirmDialog = false">{{ confirmYesNo ? $t("reportInfo.no") : $t("confirm.cancel") }}</v-btn>
          <v-btn color="green darken-1" text @click="confirmDialogYes()">{{ confirmYesNo ? $t("reportInfo.yes") : $t("confirm.ok") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="photosDialog" width="unset" content-class="photosDialog">
      <v-carousel height="auto" v-model="selectedPhotoIndex">
        <v-carousel-item v-for="(photo, i) in photos" :key="i">
          <v-sheet height="100%" tile style="max-height:85vh;">
            <img :src="photo.url.image" style="max-height:85vh;">
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </v-dialog>

    <v-snackbar v-model="showConfirmation" timeout="15000">
          {{ $t("documentRequests.email_sent") }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="green"
          text
          v-bind="attrs"
          @click="showConfirmation = false;"
        >
          {{ $t("general.close") }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="viewPdf.show" width="97%" height="100%">
      <v-card>
        <v-card-actions>
          {{viewPdf.title || ''}}
          <v-spacer></v-spacer>
          <v-icon @click="() => { viewPdf.show = false; viewPdf.data = null;}">mdi-close</v-icon>
        </v-card-actions>
        <v-card-text>
          <vue-pdf-app style="height: 83vh; display: block;" :pdf="viewPdf.data" page-scale="page-fit" theme.sync="light" :fileName="viewPdf.title + '.pdf'" :config="{ toolbar: { toolbarViewerRight: {openFile: false, viewBookmark: false} }, secondaryToolbar: false }"></vue-pdf-app>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div v-if="viewPdf.show && viewPdf.data == null" class="downloadingPdf">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
  </div> 
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import { mapGetters, mapState, mapMutations, mapActions} from "vuex";
import DocumentRequestService from "@/services/documentRequest.service";
import { DOCUMENT_REQUEST_TYPES_TEXT, REQUEST_TYPES_TEXT } from '@/enums';
import { getLongFormattedAddress } from "@/store/utility";
import FileService from "@/services/file.service";
import { emailsRules } from "@/utils/validation";
import ProjectService from "@/services/project.service";
import { parseError } from '@/utils/utils';
import moment from 'moment';
import axios from "axios";
import authHeader from "@/services/auth-header";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

const ConfirmAction = Object.freeze({ DeleteDocument: 0 });

export default {
  name: "Home",
  components: {
    Breadcrumbs,
    VuePdfApp
  },
  data() {
    return {
      locale: this.$i18n.locale,
      data: null,
      loading: false,
      uploading: false,
      showConfirmation: false,
      DOCUMENT_REQUEST_TYPES_TEXT: DOCUMENT_REQUEST_TYPES_TEXT,
      getLongFormattedAddress: getLongFormattedAddress, 
      photosDialog: false,
      selectedPhotoIndex: 0,
      confirmDialog: false,
      confirmDialogTitle: null,
      confirmDialogBody: null,
      confirmAction: null,
      confirmYesNo: true,
      documentToDelete: null,
      photos: [],
      emailsRules: emailsRules,
      emailSending: false,
      email: '',
      isRenamingAFile: false,
      viewPdf: { show: false, title: null, data: null}
    };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
      countries: (state) => state.countries
    }),
    ...mapGetters({
      canManageDealers: 'canManageDealers',
      hasAccessToDocumentRequests: 'hasAccessToDocumentRequests'
    }),
    iconWidth: () => '100px',
    iconHeight: () => '100px',

    disableBtn() {
      return this.data.files.length == 0 || !/.+@.+\..+/.test(this.email) || this.emailSending; 
    }
  },
  mounted() {
    this.getRequestDetails();
  },
  methods: {
    log: console.warn,
    ...mapMutations({
      openToast: 'openToast'
    }),
    ...mapActions([ 
      'setBreadcrumbs',
    ]),
    thumbnailClicked(doc, index) {
      if (doc.renaming) return;
      this.selectedPhotoIndex = index;
      this.photosDialog = true;
    },
    /*rotateImage(doc) {
      console.log('rotate', doc);
    },*/

    confirmDelete(doc) {
      this.confirmDialog = true;
      this.confirmDialogTitle = this.$t('confirm.head');
      this.confirmDialogBody = this.$t('confirm.file_message');
      this.confirmAction = ConfirmAction.DeleteDocument;
      this.confirmYesNo = true;
      this.documentToDelete = doc;
    },

    //Only action available for now is ConfirmAction.DeleteDocument
    async confirmDialogYes() {
      this.confirmDialog = false;
      let result = await FileService.deleteFile(this.documentToDelete, {docreqid: this.$route.params.id});
      if (!result.success) this.openToast({ message: this.$t(result.data), duration: 5000, color: 'red'});
      else this.getRequestDetails();
    },

    async downloadFile(doc, view) {
      if (doc.renaming) return;
      if (view === true && doc.extension == 'pdf') {
        this.viewPdf.show = true;
        this.viewPdf.title = doc.filename;
        try {
          let result = await axios.get(doc.url.download, { headers: authHeader(), responseType: 'blob' });
          this.viewPdf.data = await result.data.arrayBuffer();
        } catch (error) {
          console.log(error);
          this.viewPdf.show = false;
          this.openToast({ message: error, duration: 5000, color: 'red'});          
        }
      }
      else {
        window.open(doc.url.download, '_blank');
      }
    },
    async getRequestDetails() {
      this.loading = true
      let result = await DocumentRequestService.getDetails(this.$route.params.id);
      if (!result || !result.success || !result.data) {
        this.openToast({ message: this.$t(result.data), duration: 5000, color: 'red'});
        return this.$router.replace('/');
      }
      this.data = result.data;
      this.setBreadcrumbs([
          {text: this.$t('projects.projects'), href: '/projects'},
          {text: this.$t('projects.project') + ' # ' + this.data.request.site_report.project.id, href: '/project/' + this.data.request.site_report.project.id},
          {text: this.$t(REQUEST_TYPES_TEXT[this.data.request.site_report.requestType]) + " # " + this.data.request.site_report.id, href: '/report/' + this.data.request.site_report.id}
        ]);
      this.email = result.data.request.site_report.project.dealer.emailEngineeringDocuments??result.data.request.site_report.project.dealer.emailMain;
      this.loading = false;
      this.photos = [];
      for (let c of this.data.files) {
        if (c.isImage) this.photos.push(c);
        c.createdAt = moment(c.createdAt).format('YYYY/MM/DD HH:mm');
      }
      for (let l of this.data.logs) {
        l.createdAt = moment(l.createdAt).format('YYYY/MM/DD HH:mm');
      }
    },
    dragOver() {},

    dragLeave() {},

    async dropFile(e) {
      let files = Array.from(e.dataTransfer.files)
      if (e && files) {
        this.uploading = true;
        for (let file of files) {
          await this.uploadFile(file)
        }
        this.uploading = false;
        this.getRequestDetails();
      }
    },

    async handleFileUpload() {
      this.uploading = true;
      for (let file of  this.$refs.docUpload.files) {
        await this.uploadFile(file)
      }
      this.$refs.docUpload.value = '';
      this.uploading = false;
      this.getRequestDetails();
    },

    async uploadFile(file) {
      let resp = await FileService.addNewFile(file, 'document_requests', this.$route.params.id);
      if (resp.data?.alreadyExists === true) this.openToast({ message: this.$t('reportInfo.file_already_exists'), duration: 3000});
      else if (!resp.success) this.openToast({ message: this.$t(resp.data), duration: 5000, color: 'red'});
    },

    selectFileName(file) {
      let el = document.querySelector('#' + file.inputId);
      if (!el) return setTimeout(() => this.selectFileName(file), 100);
      el.focus();
      el.select();
    },

    async renameFile(file, action) {
      if (action == 'enable') {
        if (!isNaN(this.isRenamingAFile?.id) && this.isRenamingAFile.id != file.id) this.renameFile(this.isRenamingAFile, 'disable');
        file.renaming = true;
        this.isRenamingAFile = file;
        file.inputId = 'ren' + Date.now();
        setTimeout(() => this.selectFileName(file), 100);
      }
      else { //disable
        this.isRenamingAFile = false;
        file.renaming = false;
        delete file.renaming;
        delete file.inputId;
        FileService.renameFile(file);
      }
      this.$forceUpdate();
    },

    async valueChanged(valueName, newValue) {
      try {
        await ProjectService.changeValue(this.data.request.site_report.project.id, valueName, newValue);
      }
      catch(error) {
        let errorMsg = parseError(error);
        console.log(errorMsg);
        this.showErrorMessage(errorMsg);
      }
    },

    async sendMail() {
      this.emailSending = true;
      let response = await DocumentRequestService.sendMail(this.$route.params.id, this.email);
      
      if (response && response.status == 200) {
        this.showConfirmation = true;
        this.data.logs.push({
            "createdAt": moment().format('YYYY/MM/DD HH:mm'),
            "user": {
                "firstname": this.$t('general.you'),
                "lastname": ""
            }
        })
      }
      else {
        this.openToast({ message: response, duration: 15000, color: "red" });
      }
      setTimeout(function(){
         this.emailSending = false;
       }.bind(this),10000);
    }
  }
};
</script>

<style scoped>
.requests-anchor {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
  position: relative;
  display: block;
  height: 48px;
}
.requests-anchor img {
  height: 48px;
}
.requests-anchor ion-thumbnail {
  display: inline-block;
  height: 100%;
}
.wrappedfile {
  text-overflow: ellipsis !important;
  max-width: 49%;
  margin-left: 10px;
  font-size: 12px;
  display: inline-block;
  vertical-align: top;
  line-height: 15px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden;
  white-space: nowrap;
}
.request-container {
  position: relative;
  /* width: 80%; */
}
.request-container + .request-container {
  margin-top: 5px;
}

.upload-btn-wrapper {
  position: absolute;
  right: 0px;
  top: -15px;
}

.upload-btn-wrapper button {
  box-shadow: none;
  height: 50px;
  width: 50px;
}

.btn {
  border: 2px solid gray;
  color: gray;
  background-color: white;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 20px;
  font-weight: bold;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.techno-text {
  color:#009f4d;
}

.required-img{
  width:48px;
  height:48px;
  border:1px dashed black; 
  border-radius:8px;
}

.delete-photo-icon{
  position:absolute;
  top:10px;
  right:0px;
}

.rotate-photo-icon{
  position:absolute;
  top:10px;
  right:30px;
}

.download-photo-icon{
  position:absolute;
  right:60px; /* set to 60px if rotate enabled */
  top:10px;
}

.rename-photo-icon{
  position:absolute;
  right:30px;
  top:10px;
}

.editingFile {
  display: inline-block;
  padding-left: 10px;
  position: absolute;
}
</style>
